// import dotenv from "dotenv";

const fetch = require("node-fetch");

const HOSt = `https://apicall.fc.school:3000`;

async function ApiTest() {
  const apiUrl = HOSt + `/test`;
  console.log({ apiUrl });

  fetch(apiUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      console.log("Data:", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
async function PSpotLookup() {
  let id = 80;
  const apiUrl = HOSt + `/parking/${id}`;

  console.log({ apiUrl });

  fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Add any other headers as needed
    },
    body: JSON.stringify(),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      console.log("Data:", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

async function createuser(username, licenseplate) {
  const apiUrl = HOSt + `/user/create/${username}/${licenseplate}`;

  console.log({ apiUrl });

  fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Add any other headers as needed
    },
    body: JSON.stringify(),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      console.log("Data:", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

async function getparkingplaces() {
  const apiUrl = HOSt + `/parking/getparkingplaces/`;

  console.log({ apiUrl });

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers as needed
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    if (response === null) return null;
    const data = await response.json();
    console.log("Data:", data);

    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function reserveren(id, licenseplate, location, gebruikersnaam) {
  const apiUrl =
    HOSt +
    `/parking/register/${id}/${licenseplate}/${location}/${gebruikersnaam}`;

  console.log({ apiUrl });

  fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Add any other headers as needed
    },
    body: JSON.stringify(),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      console.log("Data:", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

async function annuleren(id, licenseplate) {
  const apiUrl = HOSt + `/parking/delete/${id}/${licenseplate}/`;

  console.log({ apiUrl });

  fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Add any other headers as needed
    },
    body: JSON.stringify(),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return console.log(response.json());
    })
    .then((data) => {
      console.log("Data:", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

async function uitchecken(id, licenseplate) {
  annuleren(id, licenseplate);
}

async function veranderen(licenseplate, location) {
  const apiUrl =
    HOSt + `/parking/change/${location}/${Date.now()}/${licenseplate}/`;

  console.log({ apiUrl });

  fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Add any other headers as needed
    },
    body: JSON.stringify(),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return console.log(response.json());
    })
    .then((data) => {
      console.log("Data:", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

async function getusers() {
  const apiUrl = HOSt + `/users/getall`;

  console.log({ apiUrl });

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers as needed
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    if (response === null) return null;
    const data = await response.json();
    console.log("Data:", data);

    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
module.exports = {
  ApiTest,
  PSpotLookup,
  createuser,
  getparkingplaces,
  reserveren,
  annuleren,
  uitchecken,
  veranderen,
  getusers,
};
