import {
  ApiTest,
  PSpotLookup,
  reserveren,
  annuleren,
  veranderen,
  uitchecken,
  getusers,
  createuser,
} from "./scripts/ApiCaller";
import { getparkingplaces } from "./scripts/ApiCaller";
import { TextField, Button } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { mCSS } from "./css/Main.css";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { Component } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import { Helmet } from "react-helmet";
let data = null;
let data1 = null;
let usersArray = null;

const userid = "1";

const ButtonHandler = async () => {
  const response = ApiTest();
  return response;
};

const reserveerButton = async (id, licenseplate, location, gebruikersnaam) => {
  const response = reserveren(id, licenseplate, location, gebruikersnaam);
  return response;
};

const annuleerButton = async (location, licenseplate) => {
  const response = annuleren(location, licenseplate);
  return response;
};

const veranderButton = async (location, licenseplate) => {
  const response = veranderen(licenseplate, location);
  return response;
};

const uitcheckButton = async (location, licenseplate) => {
  const response = uitchecken(location, licenseplate);
  return response;
};

let counter = 0;
let tempspots = null;
let permaspots = null;
let tempspots1 = null;
let permaspots1 = null;

function App() {
  const [parkingPlaces, setParkingPlaces] = useState([]);
  const [users, setUsers] = useState([]);
  const [renderDelayed, setRenderDelayed] = useState(false);
  const [location, setLocation] = useState("");

  const [showRes, setShowRes] = useState("show");
  const [showCan, setShowCan] = useState("hide");
  const [showUit, setShowUit] = useState("hide");
  const [showVer, setShowVer] = useState("hide");
  const [showUse, setShowUse] = useState("hide");

  const [nummerbord, setNummerbord] = useState("");
  const [gebruikersnaam, setGebruikersnaam] = useState("");

  const [userselection, setUserSelection] = useState("");

  const [open, setOpen] = React.useState(false);

  const handleNummerbordChange = (event) => {
    setNummerbord(event.target.value);
  };

  const handleGebruikersnaamChange = (event) => {
    setGebruikersnaam(event.target.value);
  };

  const reserveerButton = async (id, licenseplate, location) => {
    const response = reserveren(id, licenseplate, location);
    handleClick();
    return response;
  };

  const annuleerButton = async (location, licenseplate) => {
    const response = annuleren(location, licenseplate);
    handleClick();
    return response;
  };

  const veranderButton = async (location, licenseplate) => {
    const response = veranderen(licenseplate, location);
    handleClick();
    return response;
  };

  const createuserButton = async (gebruikersnaam, licenseplate) => {
    const response = createuser(gebruikersnaam, licenseplate);
    handleClick();
    return response;
  };

  const uitcheckButton = async (location, licenseplate) => {
    const response = uitchecken(location, licenseplate);
    handleClick();
    return response;
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        OK
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      ></IconButton>
    </React.Fragment>
  );

  function ShowHide(ID) {
    switch (ID) {
      case "Res":
        setShowRes("show");
        setShowCan("hide");
        setShowUit("hide");
        setShowVer("hide");
        setShowUse("hide");
        break;
      case "Can":
        setShowRes("hide");
        setShowCan("show");
        setShowUit("hide");
        setShowVer("hide");
        setShowUse("hide");
        break;
      case "Uit":
        setShowRes("hide");
        setShowCan("hide");
        setShowUit("show");
        setShowVer("hide");
        setShowUse("hide");
        break;
      case "Ver":
        setShowRes("hide");
        setShowCan("hide");
        setShowUit("hide");
        setShowVer("show");
        setShowUse("hide");
        break;
      case "USE":
        setShowRes("hide");
        setShowCan("hide");
        setShowUit("hide");
        setShowVer("hide");
        setShowUse("show");
        break;
    }
  }

  const LocationButton = async (text) => {
    setLocation(text);
  };

  useEffect(() => {
    // Use the imported getparkingplaces function to fetch data
    const fetchData = async () => {
      try {
        data = await getparkingplaces();
        setParkingPlaces(data);
        data1 = await getusers();
        setUsers(data1);
        setRenderDelayed(true); // Set state to trigger rendering after delay
        console.log(`delayed: true`);
      } catch (error) {
        console.error("Error fetching parking places:", error);
      } finally {
        console.log(`finally ${Object.values(data)}`);
        console.log((tempspots = Object.values(data)));
        console.log((permaspots = Object.values(tempspots[1])));
        console.log(`finally ${Object.values(data1)}`);
        console.log((tempspots1 = Object.values(data1)));
        console.log((permaspots1 = Object.values(tempspots1[1])));
        renderParkingPlaces();
        renderUsers();
      }
    };

    // Fetch data only once when the component mounts
    fetchData();
  }, []);

  const renderUsers = () => {
    console.log("Rendering parking places:", users); // Log the parkingPlaces before rendering

    if (!permaspots1) return;
    if (!Array.isArray(permaspots) || permaspots.length === 0) return;

    const perm = Object.values(tempspots1[1]);

    if (!Array.isArray(perm)) return;
    console.log("perm:", perm);
    usersArray = perm.map((user) => user.gebruikersnaam);
  };

  const renderParkingPlaces = () => {
    // console.log("Rendering parking places:", parkingPlaces); // Log the parkingPlaces before rendering

    if (!permaspots)
      return (
        <Box sx={{ width: 300 }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      );
    if (!Array.isArray(permaspots) || permaspots.length === 0) {
      // Handle the case where parkingPlaces is not an array or an empty array
      return (
        <Box sx={{ width: 300 }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      );
    }

    const perm = Object.values(tempspots[1]);

    if (!Array.isArray(perm)) {
      return (
        <Box sx={{ width: 300 }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      );
    }

    return perm.map((place, index) => (
      <ListItem key={index} disablePadding>
        <ListItemButton
          onClick={() => {
            LocationButton(place.locatie);
          }}
        >
          <ListItemIcon>{/* You can add an icon if needed */}</ListItemIcon>
          <ListItemText primary={place.locatie} />
        </ListItemButton>
      </ListItem>
    ));
  };

  return (
    // Your component JSX
    <div>
      {/* Your main content goes here */}
      <Helmet>
        <meta
          http-equiv="Content-Security-Policy"
          content="upgrade-insecure-requests"
        />
      </Helmet>
      {/* Header */}
      <header>
        <Button variant="text">Home</Button>
        <Button variant="text">About</Button>
        <Button variant="text">Contact</Button>
      </header>

      <div className="selection">
        <Button variant="text" onClick={() => ShowHide("Res")}>
          Reserveren
        </Button>
        <Button variant="text" onClick={() => ShowHide("Can")}>
          Cancelen
        </Button>
        <Button variant="text" onClick={() => ShowHide("Uit")}>
          Uitchecken
        </Button>
        <Button variant="text" onClick={() => ShowHide("Ver")}>
          veranderen
        </Button>
        <Button variant="text" onClick={() => ShowHide("USE")}>
          ADD USER
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={usersArray || []}
          sx={{ width: 300 }}
          onChange={(event, newValue) => {
            console.log(`Selected: ${newValue || ""}`); // Log the selected value
            setUserSelection(newValue); // Call your function with the selected value
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="user"
              value={userselection}
              onChange={(event, newValue) => {
                console.log(`Selected: ${newValue || ""}`); // Log the selected value
                setUserSelection(newValue); // Call your function with the selected value
              }}
            />
          )}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          variant="success"
          severity="success"
          message="successfull operation"
          action={action}
        />
      </div>
      {/* Your main content goes here */}
      <div className={showRes}>
        <div className="box">
          <div className="container">
            <div className="grid">
              <div className="grid">
                <TextField label="Locatie" value={location} readOnly />
                <TextField
                  label="Nummerbord"
                  value={nummerbord}
                  onChange={handleNummerbordChange}
                />
                <FormControlLabel control={<Switch />} label="Handicap" />
                <input type="date" id="dateInput" name="dateInput"></input>
              </div>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                  maxHeight: 150,
                  overflow: "auto",
                }}
              >
                <nav aria-label="main mailbox folders">
                  <List>{renderParkingPlaces()}</List>
                </nav>
              </Box>
            </div>
            <Button
              variant="text"
              onClick={() =>
                reserveerButton(
                  userid,
                  nummerbord,
                  location,
                  userselection.toString()
                )
              }
            >
              Reserveer
            </Button>
          </div>
        </div>
      </div>

      <div className={showCan}>
        <div className="box">
          <div className="container">
            <div className="grid">
              <div className="grid">
                <TextField label="Locatie" value={location} />
                <TextField
                  label="Nummerbord"
                  value={nummerbord}
                  onChange={handleNummerbordChange}
                />
                <FormControlLabel control={<Switch />} label="Handicap" />
                <input type="date" id="dateInput" name="dateInput"></input>
              </div>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                  maxHeight: 150,
                  overflow: "auto",
                }}
              >
                <nav aria-label="main mailbox folders">
                  <List>{renderParkingPlaces()}</List>
                </nav>
              </Box>
            </div>
            <Button
              variant="text"
              onClick={() => annuleerButton(location, nummerbord)}
            >
              cancel
            </Button>
          </div>
        </div>
      </div>

      <div className={showUit}>
        <div className="box">
          <div className="container">
            <div className="grid">
              <div className="grid">
                <TextField label="Locatie" value={location} />
                <TextField
                  label="Nummerbord"
                  value={nummerbord}
                  onChange={handleNummerbordChange}
                />
                <FormControlLabel control={<Switch />} label="Handicap" />
                <input type="date" id="dateInput" name="dateInput"></input>
              </div>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                  maxHeight: 150,
                  overflow: "auto",
                }}
              >
                <nav aria-label="main mailbox folders">
                  <List>{renderParkingPlaces()}</List>
                </nav>
              </Box>
            </div>
            <Button
              variant="text"
              onClick={() => uitcheckButton(location, nummerbord)}
            >
              uitchecken
            </Button>
          </div>
        </div>
      </div>

      <div className={showVer}>
        <div className="box">
          <div className="container">
            <div className="grid">
              <div className="grid">
                <TextField label="Locatie" value={location} />
                <TextField
                  label="Nummerbord"
                  value={nummerbord}
                  onChange={handleNummerbordChange}
                />
                <FormControlLabel control={<Switch />} label="Handicap" />
                <input type="date" id="dateInput" name="dateInput"></input>
              </div>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                  maxHeight: 150,
                  overflow: "auto",
                }}
              >
                <nav aria-label="main mailbox folders">
                  <List>{renderParkingPlaces()}</List>
                </nav>
              </Box>
            </div>
            <Button
              variant="text"
              onClick={() => veranderButton(location, nummerbord)}
            >
              Veranderen
            </Button>
          </div>
        </div>
      </div>

      <div className={showUse}>
        <div className="box">
          <div className="container">
            <div className="grid">
              <TextField
                label="username"
                value={gebruikersnaam}
                onChange={handleGebruikersnaamChange}
              />
              <TextField
                label="Nummerbord"
                value={nummerbord}
                onChange={handleNummerbordChange}
              />
            </div>
            <Button
              variant="text"
              onClick={() => createuserButton(gebruikersnaam, nummerbord)}
            >
              Add User
            </Button>
          </div>
        </div>
      </div>
      {/* Footer */}
      <footer>&copy;HarmHaandrikman</footer>
    </div>
  );
}

export default App;
